//student
// Add the Firebase products that you want to use
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// load firebase

//const idb = window.indexedDB;

const config = {
  apiKey: "AIzaSyAeM0h_gDnVpXIC9jgqe1IeyCQH7loi9HA",
  authDomain: "inisharex.firebaseapp.com",
  projectId: "inisharex",
  storageBucket: "inisharex.appspot.com",
  messagingSenderId: "731451691842",
  appId: "1:731451691842:web:8026d9461fe09bcf366edb",
  measurementId: "G-5F53HKB3Q1",
};
// export const state_changed = firebase.storage.TaskEvent.STATE_CHANGED;

export const firebaseApp = initializeApp(config);
export const functions = getFunctions(firebaseApp);
export const firestore = getFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

export const analytics = getAnalytics(firebaseApp);

// firebase.app().auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// export const fieldDelete = firebase.firestore.FieldValue.delete;
// export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
// export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

// Create a reference with an initial file path and name

// export const functions = firebase.functions();
