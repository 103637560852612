import React, { useEffect } from "react";

import "./App.css";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebaseUtils/firebaseUtil";

function App() {
  useEffect(() => {
    const loadIt = async () => {
      const bit = document.location.pathname.replace("/", "");

      const newPath = await getDoc(doc(firestore, "/bits/" + bit));

      if (newPath.data()) {
        document.location.href =
          (bit.length === 7
            ? "https://inishare.com/"
            : "https://app.inishare.com/") + newPath.data()?.url;
      } else {
        document.location.href = "https://app.inishare.com";
      }
    };

    loadIt();
  }, []);

  return <div className="App"></div>;
}

export default App;
